import { z } from "zod";

import { flaggableTypeEnum, flaggedReasonEnum } from "../../enums/moderation";

export const flagRecordSchema = z.object({
  comment: z.string().nullable().optional(),
  flaggable_id: z.number(),
  flaggable_type: flaggableTypeEnum,
  subcategory: z.string().nullable().optional(),
  reason: flaggedReasonEnum.optional(),
});

export type FlagRecord = z.infer<typeof flagRecordSchema>;
