import Cookies from "js-cookie";
import type {
  RequestCookies,
  ResponseCookies,
} from "next/dist/compiled/@edge-runtime/cookies";
import type { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";
import z from "zod";

import { consoleErrorPackagesSdk } from "../../../utils";
import type { CookieKey, CookieSchema } from "../types";

export type GetCookieProps = {
  cookies?: ReadonlyRequestCookies | RequestCookies | ResponseCookies;
  key: CookieKey;
  schema?: CookieSchema;
};

export const getCookie = <T extends any = string>({
  cookies,
  key,
  schema,
}: GetCookieProps): T => {
  const cookie = cookies ? cookies.get(key)?.value : Cookies.get(key);

  if (!cookie) return null;

  try {
    return (schema ?? z.string()).parse(
      cookie.startsWith("{") ? JSON.parse(cookie) : cookie,
    );
  } catch (error) {
    consoleErrorPackagesSdk({
      error,
      heading: `Could not validate ${key} cookie`,
    });
    return null;
  }
};
