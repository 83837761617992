import { sessionsCountSchema } from "../../../../lib";
import { request } from "../../../../request";

export const requestSessionsCountTrusted = () =>
  request({
    next: { tags: [`sessions-count`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: sessionsCountSchema,
    urlBackendPath: "/trusted/sessions/count",
  });
