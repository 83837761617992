import { z } from "zod";

export const requestTypeEnum = z.enum([
  "authenticated",
  "default",
  "search",
  "trusted",
]);

export type RequestType = z.infer<typeof requestTypeEnum>;
