import { z } from "zod";

export const communityUserRelationshipStateEnum = z.enum([
  "friends",
  "none",
  "pending",
  "requested",
  "self",
]);

export type CommunityUserRelationshipState = z.TypeOf<
  typeof communityUserRelationshipStateEnum
>;
