import { z } from "zod";

import { imagesSchema } from "../imagesSchema";

export const nextUpSchema = z.object({
  type: z.string(),
  title: z.string(),
  image_url: z.string(),
  deeplink: z.string(),
  reason_code: z.string(),
  reason_label: z.string(),
  color_hex: z.string(),
  has_access: z.boolean(),
  description: z.string(),
  images: imagesSchema.pick({ large: true, color_hex: true }),
});

export type NextUp = z.infer<typeof nextUpSchema>;
