import { z } from "zod";

export enum CommunityType {
  basic = "basic",
  parish = "parish",
  network = "network",
  small_group = "small_group",
}

export const communityTypeEnum = z.nativeEnum(CommunityType);
