"use client";

import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminCommunities } from "./keyCommunitiesAdminCommunities";
import { requestCommunitiesAdminCommunitiesProps } from "./requestCommunitiesAdminCommunities";

export type UseRequestCommunitiesAdminCommunitiesProps = UseRequestQueryProps;

export const useRequestCommunitiesAdminCommunities = ({
  ...props
}: UseRequestCommunitiesAdminCommunitiesProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: requestCommunitiesAdminCommunitiesProps,
    queryKey: keyCommunitiesAdminCommunities(),
  }),
});
