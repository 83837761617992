import { z } from "zod";

import { communitySchema } from "../communitySchema";

export const communityUpdateDetailsSchema = communitySchema
  .pick({ name: true, description: true, address: true })
  .extend({
    is_public: z.boolean(),
    avatar_id: z.number(),
    is_nonadmin_content_allowed: z.boolean(),
  })
  .partial();

export type CommunityUpdateDetails = z.infer<
  typeof communityUpdateDetailsSchema
>;
