"use client";

import { useLocale } from "@packages/i18n";
import { usePathname } from "next/navigation";

import { pathMatchFn } from "./pathMatchFn";

export const usePathMatch = () => {
  const pathname = usePathname();
  const locale = useLocale();

  return (href: string, exact: boolean = false) =>
    pathMatchFn(href, exact, pathname, locale);
};
