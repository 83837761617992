import { z } from "zod";

import { guideSchema } from "../guideSchema";
import { trackSchema } from "../trackSchema";
import { basePrayerSchema } from "./basePrayerSchema";

export const trustedPrayerSchema = basePrayerSchema
  .omit({
    has_access: true,
    is_completed: true,
    is_favorite: true,
    is_redeemed: true,
  })
  .extend({ tracks: z.array(trackSchema), guides: z.array(guideSchema) });

export type TrustedPrayer = z.infer<typeof trustedPrayerSchema>;
