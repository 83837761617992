import type { CampaignMessage } from "../../../../lib";
import type { UseRequestMutationProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";

type UseRequestCampaignMessageVariables = Pick<CampaignMessage, "text"> & {
  type: string;
};

export type UseRequestCampaignMessageProps = UseRequestMutationProps<
  any,
  UseRequestCampaignMessageVariables
> & {
  id: number;
};

export const useRequestCampaignMessage = ({
  id,
  ...props
}: UseRequestCampaignMessageProps) => ({
  mutationPost: useRequestMutation({
    ...props,
    mutationFnRequestProps: (data: UseRequestCampaignMessageVariables) => ({
      body: JSON.stringify(data),
      urlBackendPath: `/campaigns/${id}/message`,
    }),
  }),
});
