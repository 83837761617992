import { z } from "zod";

import { deeplinkSchema } from "../deeplinkSchema";

export const bibleDeeplinkSchema = z.object({
  top_content_deeplink: deeplinkSchema.nullable(),
  bottom_content_deeplink: deeplinkSchema.nullable(),
});

export type BibleDeeplinks = z.infer<typeof bibleDeeplinkSchema>;
