"use client";

import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";

type UseRequestPhoneOtpVariables = { phone: string };

export type UseRequestOtpProps = UseRequestMutationProps<
  any,
  UseRequestPhoneOtpVariables
>;

export const useRequestOtp = ({ ...props }: UseRequestOtpProps = {}) => ({
  mutationPost: useRequestMutation({
    ...props,
    mutationFnRequestProps: (data: UseRequestPhoneOtpVariables) => ({
      body: JSON.stringify(data),
      urlBackendPath: "/otp",
    }),
  }),
});
