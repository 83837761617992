import { z } from "zod";

export const deeplinkSchema = z.object({
  color_hex: z.string(),
  id: z.number(),
  title: z.string(),
  description: z.string(),
  image_url: z.string().url(),
  deeplink: z.string(),
});

export type DeeplinkSchema = z.infer<typeof deeplinkSchema>;
