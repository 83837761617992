"use client";

import { routineResponseSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyRoutine } from "./keyRoutine";

export type UseRequestRoutineProps = UseRequestQueryProps;

export const useRequestRoutine = ({
  ...props
}: UseRequestRoutineProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: routineResponseSchema,
      urlBackendPath: "/routine/v2",
    },
    queryKey: keyRoutine(),
  }),
});
