import { z } from "zod";

import { guideSchema } from "../guideSchema";
import { trackSchema } from "../trackSchema";
import { basePrayerSchema } from "./basePrayerSchema";

export const playablePrayerSchema = basePrayerSchema.extend({
  tracks: z.array(trackSchema),
  guides: z.array(guideSchema),
});
