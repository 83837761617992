"use client";

import { avatarsSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyAvatars } from "./keyAvatars";

export type UseRequestAvatarsProps = UseRequestQueryProps;

export const useRequestAvatars = ({ ...props }: UseRequestQueryProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: avatarsSchema,
      urlBackendPath: "/avatars",
    },
    queryKey: keyAvatars(),
  }),
});
