"use client";

import { sessionsCountSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keySessionsCount } from "./keySessionsCount";

export type UseRequestSessionsCountProps = UseRequestQueryProps;

export const useRequestSessionsCount = ({
  ...props
}: UseRequestSessionsCountProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: sessionsCountSchema,
      urlBackendPath: "/sessions/count",
    },
    queryKey: keySessionsCount(),
  }),
});
