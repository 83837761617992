import { z } from "zod";

const answerSchema = z.object({
  id: z.number(),
  text: z.string(),
  correct: z.boolean(),
  percentage: z.number(),
});

const userAnswerSchema = z.object({
  game_id: z.number().optional(),
  user_id: z.number().optional(),
  question_id: z.number().optional(),
  answer_id: z.number(),
  seconds_elapsed: z.number(),
});

const questionSchema = z.object({
  id: z.number(),
  text: z.string(),
  category: z.string(),
  user_answer: userAnswerSchema.nullable(),
  answers: z.array(answerSchema),
});

export type TriviaQuestion = z.infer<typeof questionSchema>;

const summarySchema = z.object({
  total_seconds_elapsed: z.number(),
  streak: z.number(),
  longest_streak: z.number(),
});

const recommendationSchema = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  image_url: z.string().url(),
  deeplink: z.string(),
});

export const triviaStateEnum = z.enum(["UNSTARTED", "STARTED", "FINISHED"]);

export type TriviaState = z.infer<typeof triviaStateEnum>;

export const triviaGameSchema = z.object({
  id: z.number(),
  player_count: z.number(),
  available_at: z.string(),
  ends_at: z.string(),
  state: triviaStateEnum,
  questions: z.array(questionSchema),
  summary: summarySchema,
  recommendations: z.array(recommendationSchema),
});

export type TriviaGame = z.infer<typeof triviaGameSchema>;
