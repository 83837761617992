export const replaceS3ImageUrlWithImagesHallowApp = (
  originalUrl: string = "",
) => {
  return process.env.NODE_ENV === "development"
    ? originalUrl.replace(
        "hallow-images-dev.s3.us-east-1.amazonaws.com",
        "images-dev.hallow.app",
      )
    : originalUrl.replace(
        "hallow-images.s3.us-east-1.amazonaws.com",
        "images.hallow.app",
      );
};
