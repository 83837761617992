import { dailyReadingsSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestDailyReadingsProps = {
  date: `${number}-${number}-${number}`;
};

export const requestDailyReadings = ({ date }: RequestDailyReadingsProps) =>
  request({
    requestType: "trusted",
    schema: dailyReadingsSchema,
    urlBackendPath: `/trusted/bible/daily-readings?date=${date}&language=en`,
  });
