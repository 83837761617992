import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";

import type { UseRequestMutationProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";
import { keyFavorites } from "../../favorites";
import { keyCollection } from "../keyCollection";

export type UseRequestCollectionFavoriteProps = UseRequestMutationProps & {
  id: number;
};

export const useRequestCollectionFavorite = ({
  id,
  ...props
}: UseRequestCollectionFavoriteProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationPost: useRequestMutation({
      ...props,
      mutationFnRequestProps: {
        urlBackendPath: `/collections/${id}/favorite`,
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [keyCollection({ id }), locale],
        });
        queryClient.invalidateQueries({
          queryKey: [keyFavorites({ displayType: "collections" }), locale],
        });
      },
    }),
  };
};
