import type { ZodSchema } from "zod";
import { z } from "zod";

import { URL_BACKEND } from "../lib";
import type { OauthRefresh } from "../types";
import { consoleErrorPackagesSdk, validatePromiseSchema } from "../utils";
import type { RequestPropsNext, RequestType } from "./types";
import type { GetRequestHeadersProps } from "./utils";
import { getRequestHeaders } from "./utils";

export type RequestProps<SchemaType extends ZodSchema = any> = RequestInit &
  RequestPropsNext & {
    headersValues?: GetRequestHeadersProps["values"];
    refresh?: (refreshProps?: {
      callback: (props: { access_token: string }) => void;
    }) => Promise<OauthRefresh>;
    requestType?: RequestType;
    retry?: boolean;
    schema?: SchemaType;
  } & (
    | {
        url: string;
        urlBackendPath?: never;
      }
    | {
        url?: never;
        urlBackendPath: string;
      }
  );

export const request = async <SchemaType extends ZodSchema = any>({
  headers = {},
  headersValues,
  method = "GET",
  refresh,
  requestType = "authenticated",
  retry,
  schema = z.any() as any,
  url,
  urlBackendPath,
  ...props
}: RequestProps<SchemaType>) =>
  fetch(urlBackendPath ? `${URL_BACKEND}${urlBackendPath}` : (url ?? ""), {
    credentials: "include",
    method,
    ...props,
    headers: {
      ...(await getRequestHeaders({ requestType, values: headersValues })),
      ...headers,
    },
  })
    .then((response): Promise<z.TypeOf<SchemaType>> => {
      if (!response.ok) throw response;

      if (response.status === 204) return;

      return validatePromiseSchema({
        promise: response.json(),
        schema,
      });
    })
    .catch(async (error) => {
      if (
        error.status === 401 &&
        urlBackendPath !== "/oauth/refresh" &&
        refresh &&
        !retry
      ) {
        const { access_token } = await refresh();
        if (access_token) {
          return request({
            ...props,
            headersValues: {
              ...headersValues,
              token: access_token,
            },
            urlBackendPath,
            method,
            retry: true,
          });
        }
      }
      consoleErrorPackagesSdk({
        error: error,
        heading: `Failed request ${method} ${urlBackendPath ? `${URL_BACKEND}${urlBackendPath}` : (url ?? "")}`,
      });
      throw error;
    });
