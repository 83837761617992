"use client";

import { userSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunityUser } from "./keyCommunityUser";

export type UseRequestCommunityUserProps = UseRequestProps & {
  id: number;
};

export const useRequestCommunityUser = ({
  id,
  ...props
}: UseRequestCommunityUserProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: userSchema,
      urlBackendPath: `/community/users/${id}`,
    },
    queryKey: keyCommunityUser({ id }),
  }),
});
