import { trim } from "./trim";

export const pathMatchFn = (
  href: string | RegExp,
  exact: boolean,
  pathname: string,
  locale: string,
): boolean => {
  if (typeof href === "string") {
    if (!href.startsWith("/")) href = `/${href}`;
  } else {
    href = trim(href.toString(), "/");
  }
  const closer = exact ? "$" : "";
  const re = new RegExp(`^(?:/${locale})?${href}/?${closer}`);
  return re.test(pathname);
};
