import { z } from "zod";

export const communitiesAdminStatsSchema = z.object({
  prayers_completed: z.number(),
  total_minutes_prayed: z.number(),
  member_count: z.number(),
  intentions_count: z.number(),
});

export type CommunitiesAdminStats = z.infer<typeof communitiesAdminStatsSchema>;
