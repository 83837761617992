import { z } from "zod";

import campaignMessageSchema from "./campaignMessageSchema";
import { campaignSchema } from "./campaignSchema";
import { playlistContentSchema } from "./playlistContentResponseSchema";

export const campaignDetailsSchema = z.object({
  campaign: campaignSchema,
  playlist_content: z.array(playlistContentSchema),
  latest_posts: z.array(campaignMessageSchema),
  updates: z.array(campaignMessageSchema),
});

export type CampaignDetails = z.infer<typeof campaignDetailsSchema>;
