import type { GetCookieRefreshTokenProps } from "../../lib";
import { getCookieRefreshToken } from "../../lib";

export type GetSessionRefreshTokenProps = GetCookieRefreshTokenProps;

export const getSessionRefreshToken = ({
  ...props
}: GetSessionRefreshTokenProps = {}) => ({
  refreshToken: getCookieRefreshToken(props),
});
