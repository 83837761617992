import { z } from "zod";

import { routineSchema } from "./routineSchema";

export const routineResponseSchema = z.object({
  show_merge_prompt: z.boolean(),
  show_queue_prompt: z.boolean(),
  routines: routineSchema.array(),
});

export type RoutineResponse = z.infer<typeof routineResponseSchema>;
