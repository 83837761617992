import { HEADERS } from "../../../constants";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestSessionLogoutProps = Pick<RequestProps, "headers">;

export const requestSessionLogout = ({
  headers = {},
}: RequestSessionLogoutProps) =>
  request({
    headers: { ...HEADERS, ...headers },
    method: "POST",
    urlBackendPath: "/me/logout",
  });
