import { z } from "zod";

import { guideSchema } from "../guideSchema";
import { trackSchema } from "../trackSchema";
import { playablePrayerSchema } from "./playablePrayerSchema";

export const basePrayerInCollectionSchema = playablePrayerSchema
  .omit({
    images: true,
  })
  .extend({
    tracks: z.array(trackSchema),
    guides: z.array(guideSchema),
    selected_track_id: z.number().nullable(),
  });

export type BasePrayerInCollection = z.infer<
  typeof basePrayerInCollectionSchema
>;
