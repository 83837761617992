import { z } from "zod";

import { communityUserRelationshipStateEnum } from "../../../enums";

export const communityUserSchema = z.object({
  bio: z.string().nullable(),
  id: z.number(),
  image_url: z.string(),
  is_friend: z.boolean(),
  is_self: z.boolean(),
  last_name: z.string(),
  name: z.string(),
  relation_description: z.string().nullable(),
  relationship_state: communityUserRelationshipStateEnum,
  username: z.string(),
});

export type CommunityUserSchema = z.TypeOf<typeof communityUserSchema>;
