import { z } from "zod";

import { communityUserSchema } from "../../community";

export const communityMembersSchema = z.object({
  results: z.array(communityUserSchema),
  total: z.number(),
});

export type CommunityMembersSchema = z.TypeOf<typeof communityMembersSchema>;
