"use client";

import { communitiesAdminFlaggableSchema } from "../../../../../lib";
import type { UseRequestQueryProps } from "../../../../../request";
import { useRequestQuery } from "../../../../../request";
import { keyCommunitiesAdminFlaggable } from "./keyCommunitiesAdminFlaggable";

export type UseRequestCommunitiesAdminFlaggableProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestCommunitiesAdminFlaggable = ({
  id,
  staleTime,
  ...props
}: UseRequestCommunitiesAdminFlaggableProps) => ({
  query: useRequestQuery({
    enabledWithoutSessionToken: false,
    staleTime,
    ...props,
    queryFnRequestProps: {
      schema: communitiesAdminFlaggableSchema,
      urlBackendPath: `/moderation/communities/${id}`,
    },
    queryKey: keyCommunitiesAdminFlaggable({
      hasStaleTime: staleTime ? true : false,
      id,
    }),
  }),
});
