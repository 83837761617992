import { z } from "zod";

import { playablePrayerSchema } from "../prayer";

export const playlistContentSchema = z.object({
  id: z.number(),
  date: z.string(),
  content_type: z.string(),
  content: playablePrayerSchema,
});

export type PlaylistContent = z.infer<typeof playlistContentSchema>;
