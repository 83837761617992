import type { BaseKeyFunction, BibleChapterUniqueId } from "../../../lib";

export type KeyBibleLinkedContentProps = {
  translationId: number;
  chapterId: BibleChapterUniqueId;
};

export const keyBibleLinkedContent: BaseKeyFunction<
  KeyBibleLinkedContentProps
> = ({ translationId, chapterId }) => [
  "bible-deeplinks",
  translationId,
  chapterId,
];
