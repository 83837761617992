import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";

import { displayTypeEnum } from "../../../lib";
import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";
import { keyFavorites } from "../favorites";
import { keyHome } from "../home";
import { keyPrayer } from "../prayer";

export type UseRequestFavoriteProps = UseRequestMutationProps & {
  id: number;
};

export const useRequestFavorite = ({
  id,
  ...props
}: UseRequestFavoriteProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationPost: useRequestMutation({
      ...props,
      mutationFnRequestProps: {
        urlBackendPath: `/prayers/${id}/favorite`,
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [keyHome(), locale] });
        queryClient.invalidateQueries({
          queryKey: [keyPrayer({ id }), locale],
        });

        displayTypeEnum.options.forEach((displayTypeEnumOption) =>
          queryClient.invalidateQueries({
            queryKey: [
              keyFavorites({ displayType: displayTypeEnumOption }),
              locale,
            ],
          }),
        );
      },
    }),
  };
};
