import type { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";

import { determineClientOrServer } from "../../utils";
import { getSessionRefreshToken } from "./getSessionRefreshToken";
import { requestSessionRefresh } from "./requests";

export type GetSessionRequestHeadersProps = {
  valueToken?: string;
};

export const getSessionRequestHeaders = async ({
  valueToken,
}: GetSessionRequestHeadersProps): Promise<HeadersInit> => {
  if (valueToken) return { Authorization: `Bearer ${valueToken}` };

  let cookies: null | ReadonlyRequestCookies;

  if (determineClientOrServer() === "server") {
    const { cookies: nextHeadersCookies } = await import("next/headers");
    cookies = nextHeadersCookies();
  }

  const { refreshToken } = getSessionRefreshToken({ cookies });
  const { access_token: token } = await requestSessionRefresh({ refreshToken });
  return { Authorization: `Bearer ${token}` };
};
