import { z } from "zod";

import { cloudfrontCookieSchema } from "../cloudfrontCookieSchema";

export const queueHlsObject = z.object({
  url: z.string(),
  cookies: cloudfrontCookieSchema.array(),
});

export type QueueHlsObject = z.infer<typeof queueHlsObject>;
