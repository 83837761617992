"use client";

import { useContext, useEffect } from "react";

import { redirectToAuth } from "../../lib";
import { sessionContext } from "../sessionContext";

export type UseSessionProps = {
  onUnauthenticated?: Function;
  required?: boolean;
};

export const useSession = ({
  onUnauthenticated,
  required = false,
}: UseSessionProps = {}) => {
  const { status, ...rest } = useContext(sessionContext);

  useEffect(() => {
    if (status === "unauthenticated" && required) {
      if (onUnauthenticated) onUnauthenticated();
      else redirectToAuth();
    }
  }, [required, status]);

  return {
    status,
    ...rest,
  };
};
