"use client";

import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";

import type { UseRequestMutationProps } from "../../../../request";
import { useRequestMutation } from "../../../../request";
import { keyCommunitiesAdminFlaggable } from "./flaggable";

type UseCommunitiesAdminPostVariables = {
  postId: number;
};

export type UseCommunitiesAdminPostProps = {
  id: number;
  mutationDeleteProps?: UseRequestMutationProps<
    any,
    UseCommunitiesAdminPostVariables
  >;
  mutationResolveProps?: UseRequestMutationProps<
    any,
    UseCommunitiesAdminPostVariables
  >;
};

export const useCommunitiesAdminPost = ({
  id,
  mutationDeleteProps,
  mutationResolveProps,
}: UseCommunitiesAdminPostProps) => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  const invalidateQueries = () =>
    queryClient.invalidateQueries({
      queryKey: [keyCommunitiesAdminFlaggable({ id }), locale],
    });

  return {
    mutationDelete: useRequestMutation({
      ...mutationDeleteProps,
      mutationFnRequestProps: ({ postId }: { postId: number }) => ({
        method: "DELETE",
        urlBackendPath: `/moderation/communities/${id}/posts/${postId}`,
      }),
      onSuccess: (data, variables, context) => {
        invalidateQueries();
        if (mutationDeleteProps?.onSuccess)
          mutationDeleteProps.onSuccess(data, variables, context);
      },
    }),
    mutationResolve: useRequestMutation({
      ...mutationResolveProps,
      mutationFnRequestProps: ({ postId }: { postId: number }) => ({
        urlBackendPath: `/moderation/communities/${id}/posts/${postId}/mark-as-reviewed`,
      }),
      onSuccess: (data, variables, context) => {
        invalidateQueries();
        if (mutationResolveProps?.onSuccess)
          mutationResolveProps.onSuccess(data, variables, context);
      },
    }),
  };
};
