import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export type RequestCampaignUsersProps = Pick<RequestProps, "method"> & {
  id: number;
};

export const requestCampaignUsers = ({
  id,
  ...props
}: RequestCampaignUsersProps) =>
  request({
    urlBackendPath: `/campaigns/${id}/users`,
    ...props,
  });
