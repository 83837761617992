"use client";

import { paymentsSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyMePayments } from "./keyMePayments";

export type UseRequestMePaymentsProps = UseRequestQueryProps;

export const useRequestMePayments = ({
  ...props
}: UseRequestMePaymentsProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: paymentsSchema,
      urlBackendPath: "/me/payments",
    },
    queryKey: keyMePayments(),
  }),
});
