import { z } from "zod";

import { basePrayerSchema } from "../../prayer";

export const communitiesAdminMostPopularPrayersSchema = z.object({
  results: basePrayerSchema.array(),
  total: z.number(),
});

export type CommunitiesAdminMostPopularPrayers = z.infer<
  typeof communitiesAdminMostPopularPrayersSchema
>;
