"use client";

import type { UseRequestProps } from "../../../request";
import { useRequest } from "../../../request";

export type UseRequestSessionProps = UseRequestProps;

export const useRequestSession = ({
  ...props
}: UseRequestSessionProps = {}) => ({
  requestPut: useRequest({
    ...props,
    fnRequestProps: (data: {
      audio_id: number;
      current_position: number;
      duration: number;
      prayer_id: number;
      uuid: string;
    }) => {
      const { duration, uuid } = data;
      delete data.uuid;

      return {
        body: JSON.stringify({
          ...data,
          duration: duration > 0 ? duration : undefined,
        }),
        method: "PUT",
        urlBackendPath: `/sessions/${uuid}`,
      };
    },
  }),
});
