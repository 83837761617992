"use client";

import { useTranslations } from "next-intl";
import { Fragment } from "react";

import type { BaseI18nMessageProps } from "../types";

export type I18nMessageClientProps = BaseI18nMessageProps;

export const I18nMessageClient = ({
  formats,
  message,
  values,
}: I18nMessageClientProps) => {
  const t = useTranslations();

  return <Fragment>{t(message, values, formats)}</Fragment>;
};
