"use client";

import { sectionSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keySection } from "./keySection";

export type UseRequestSectionProps = UseRequestQueryProps & {
  id: string;
};

export const useRequestSection = ({
  id,
  ...props
}: UseRequestSectionProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: sectionSchema,
      urlBackendPath: `/sections/${id}`,
    },
    queryKey: keySection({ id }),
  }),
});
