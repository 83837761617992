import { z } from "zod";

import { communityChallengeStatusEnum } from "../../../enums";
import { imagesSchema } from "../../imagesSchema";
import { userSchema } from "../../user";
import { communitySchema } from "../communitySchema";

export const communityChallengeSchema = z.object({
  id: z.number(),
  starts_at: z.string().datetime(),
  ends_at: z.string().datetime(),
  organizer: userSchema,
  content_title: z.string(),
  has_joined: z.boolean(),
  content_image: imagesSchema,
  status: communityChallengeStatusEnum,
  labels: z.array(z.string()),
  desc: z.string(),
  short_desc: z.string(),
  sessions: z.string(),
  has_access: z.boolean(),
  community: communitySchema,
  is_organizer_admin: z.boolean(),
});

export type CommunityChallenge = z.infer<typeof communityChallengeSchema>;
