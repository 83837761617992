import type { BaseKeyFunction } from "../../../lib";

export type KeyTranscriptProps = {
  id: number;
};

export const keyTranscript: BaseKeyFunction<KeyTranscriptProps> = ({ id }) => [
  "transcript",
  id?.toString(),
];
