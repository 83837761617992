import { z } from "zod";

export const dailyQuoteSchema = z.object({
  id: z.number(),
  text: z.string(),
  attributed_to: z.string(),
  image_url: z.string(),
  shareable_url: z.string(),
});

export type DailyQutoe = z.infer<typeof dailyQuoteSchema>;
