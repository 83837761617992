import { z } from "zod";

import { imagesSchema } from "../imagesSchema";

export const baseCollectionSchema = z.object({
  desc: z.string(),
  has_access: z.boolean().optional(),
  has_high_res_illo: z.boolean().optional(),
  id: z.number(),
  is_album: z.boolean(),
  paid: z.boolean(),
  prayers_count: z.number(),
  sessions: z.string().nullable(),
  short_desc: z.string(),
  style_id: z.number().optional(),
  supertitle: z.string(),
  title: z.string(),
  images: imagesSchema,
  type: z.string(),
  available_at: z.string().nullable(),
  ends_at: z.string().nullable(),
  participants_tag: z.string().nullable().optional(),
});

export type BaseCollection = z.infer<typeof baseCollectionSchema>;
