import {
  HEADERS,
  HEADERS_AUTHENTICATED,
  HEADERS_SEARCH,
  HEADERS_TRUSTED,
} from "../../constants";
import { getI18nRequestHeaders, type Locale } from "../../i18n";
import { getSessionRequestHeaders } from "../../session";
import type { RequestType } from "../types";

export type GetRequestHeadersProps = {
  requestType: RequestType;
  values?: {
    appSession?: string;
    locale?: Locale;
    token?: string;
  };
};

export const getRequestHeaders = async ({
  values,
  requestType,
}: GetRequestHeadersProps): Promise<HeadersInit> => {
  let headers: HeadersInit = HEADERS;

  if (values?.appSession)
    headers = {
      ...headers,
      "X-Hallow-App-Session": values?.appSession,
    };

  headers = {
    ...headers,
    ...(await getI18nRequestHeaders({ valueLocale: values?.locale })),
  };

  if (requestType === "authenticated")
    headers = {
      ...headers,
      ...(await getSessionRequestHeaders({ valueToken: values?.token })),
    };

  switch (requestType) {
    case "authenticated":
      return { ...headers, ...HEADERS_AUTHENTICATED };
    case "search":
      return { ...headers, ...HEADERS_SEARCH };
    case "trusted":
      return { ...headers, ...HEADERS_TRUSTED };
    case "default":
    default:
      return headers;
  }
};
