import { z } from "zod";

export enum FlaggableType {
  Campaign = "campaign",
  Community = "community",
  Intention = "intention",
  Post = "post",
  PromptResponse = "prompt_response",
  User = "user",
}

export const flaggableTypeEnum = z.nativeEnum(FlaggableType);
