import Cookies from "js-cookie";
import type {
  RequestCookies,
  ResponseCookies,
} from "next/dist/compiled/@edge-runtime/cookies";
import type { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";

import type { CookieKey } from "../types";
export type SetCookieProps = {
  cookies?: ReadonlyRequestCookies | RequestCookies | ResponseCookies;
  key: CookieKey;
  value: any;
  domain?: string;
  options?: Record<string, any>;
};

export const setCookie = ({
  cookies,
  key,
  value,
  domain = ".hallow.com",
  options = {},
}: SetCookieProps) => {
  const cookieOptions = {
    domain,
    path: "/",
    ...options,
  };

  if (cookies) {
    cookies.set(key, value, cookieOptions);
  } else {
    Cookies.set(key, value, cookieOptions);
  }
};
