import { z } from "zod";

export enum CommunityChallengeStatus {
  pending = "PENDING",
  active = "ACTIVE",
  finished = "FINISHED",
}

export const communityChallengeStatusEnum = z.nativeEnum(
  CommunityChallengeStatus,
);
