import { Suspense } from "react";

import type { I18nProviderClientProps } from "./I18nProviderClient";
import { I18nProviderClient } from "./I18nProviderClient";

export type I18nProviderProps = I18nProviderClientProps;

export const I18nProvider = ({ children, ...props }: I18nProviderProps) => (
  <Suspense>
    <I18nProviderClient {...props}>{children}</I18nProviderClient>
  </Suspense>
);
