import { campaignDetailsSchema } from "../../../../lib";
import { request } from "../../../../request";

export type RequestCampaignDetailsTrustedProps = {
  id: number;
};

export const requestCampaignDetailsTrusted = ({
  id,
}: RequestCampaignDetailsTrustedProps) =>
  request({
    next: { tags: [`campaign-details-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: campaignDetailsSchema,
    urlBackendPath: `/trusted/campaign-details/${id}`,
  });
