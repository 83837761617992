import { communitySchema } from "../../../../lib/types/schema/community/communitySchema";
import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export type RequestCommunitiesMembersProps = Omit<RequestProps, "url"> & {
  id: number;
};

export const requestCommunitiesMembers = ({
  id,
  ...props
}: RequestCommunitiesMembersProps) =>
  request({
    ...props,
    schema: communitySchema,
    urlBackendPath: `/me/communities/${id}/members`,
  });
