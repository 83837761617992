"use client";

import { prayerSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyPrayer } from "./keyPrayer";

export type UseRequestPrayerProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestPrayer = ({ id, ...props }: UseRequestPrayerProps) => ({
  query: useRequestQuery({
    enabled: typeof id === "number",
    ...props,
    queryFnRequestProps: {
      schema: prayerSchema,
      urlBackendPath: `/prayers/${id}`,
    },
    queryKey: keyPrayer({ id }),
  }),
});
