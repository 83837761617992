import { z } from "zod";

import { cloudfrontCookieSchema } from "../cloudfrontCookieSchema";
import { bibleLanguageList } from "./bibleLanguageList";

export const bibleTranslationsSchema = z.object({
  translations: z
    .object({
      id: z.number(),
      name: z.string(), // abbreviation, normally
      description: z.string(),
      url: z.string().url(), // zip file
      language: bibleLanguageList,
      available: z.boolean(),
      version: z.string(),
    })
    .array(),
  cookies: cloudfrontCookieSchema.array(),
});

export type BibleTranslations = z.infer<typeof bibleTranslationsSchema>;
