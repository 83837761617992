import { z } from "zod";

import { request } from "../../../request";

export const requestRefreshTokenDelete = () =>
  request({
    method: "DELETE",
    requestType: "default",
    schema: z.literal(true),
    urlBackendPath: "/oauth/refresh",
  });
