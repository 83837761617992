import { communityChallengeSchema } from "../../../../lib/types/schema/community/challenge";
import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export type RequestCommunityChallengeProps = Omit<RequestProps, "url"> & {
  id: number;
};

export const requestCommunityChallengeTrusted = ({
  id,
  ...props
}: RequestCommunityChallengeProps) =>
  request({
    ...props,
    next: { tags: [`community-challenge-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: communityChallengeSchema,
    urlBackendPath: `/trusted/community-challenges/${id}`,
  });
