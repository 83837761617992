import { sectionSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestSectionTrustedProps = Partial<RequestProps> & {
  id: string;
};

export const requestSectionTrusted = ({ id }: RequestSectionTrustedProps) =>
  request({
    next: { tags: [`sections-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: sectionSchema,
    urlBackendPath: `/trusted/sections/${id}`,
  });
