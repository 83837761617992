"use client";

import { collectionSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyCollection } from "./keyCollection";

export type UseRequestCollectionProps = UseRequestQueryProps & {
  id: number;
  type?: "collection" | "chapter" | "challenge";
};

export const useRequestCollection = ({
  id,
  type = "collection",
  ...props
}: UseRequestCollectionProps) => ({
  ...props,
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: collectionSchema,
      urlBackendPath: `/collections/${id}${type !== "collection" ? `?type=${type}` : ""}`,
    },
    queryKey: keyCollection({ id }),
  }),
});
