import { z } from "zod";

export const bibleLanguageList = z.enum([
  "en",
  "ar",
  "de",
  "es",
  "fr",
  "hi",
  "it",
  "pl",
  "pt",
  "tl",
]);

export type BibleLanguage = z.infer<typeof bibleLanguageList>;
