import { homeSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyHome } from "./keyHome";

export type UseRequestHomeProps = UseRequestQueryProps;

export const useRequestHome = ({ ...props } = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: homeSchema,
      urlBackendPath: "/home",
    },
    queryKey: keyHome(),
  }),
});
