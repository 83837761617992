import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_HAS_REFRESH_TOKEN: CookieKey = "hashlwrfh";

export type DeleteCookieHasRefreshTokenProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieHasRefreshToken = ({
  ...props
}: DeleteCookieHasRefreshTokenProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_HAS_REFRESH_TOKEN, ...props });

export type GetCookieHasRefreshTokenProps = Omit<GetCookieProps, "key">;

export const getCookieHasRefreshToken = ({
  ...props
}: GetCookieHasRefreshTokenProps = {}) =>
  getCookie({ key: COOKIE_KEY_HAS_REFRESH_TOKEN, ...props });

export type SetCookieHasRefreshTokenProps = Omit<SetCookieProps, "key">;

export const setCookieHasRefreshToken = ({
  ...props
}: SetCookieHasRefreshTokenProps) =>
  setCookie({ key: COOKIE_KEY_HAS_REFRESH_TOKEN, ...props });
