import { deepMerge } from "@packages/sdk";

export type DeepMergeMessagesProps = {
  defaultMessages: any;
  userMessages: any;
};

export const deepMergeMessages = ({
  defaultMessages,
  userMessages,
}: DeepMergeMessagesProps) => deepMerge(defaultMessages, userMessages);
