import { z } from "zod";

export enum FlaggedReason {
  DeceptiveContent = "deceptive_content",
  HarmfulBehavior = "harmful_behavior",
  InappropriateContent = "inappropriate_content",
  MentalHealthAndSafety = "mental_health_and_safety",
  SomethingElse = "something_else",
}

export const flaggedReasonEnum = z.nativeEnum(FlaggedReason);
