import { homeSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestHomeTrustedProps = {
  date: string;
};

export const requestHomeTrusted = ({ date }: RequestHomeTrustedProps) =>
  request({
    next: { tags: [`home`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: homeSchema,
    urlBackendPath: `/trusted/home/${date}`,
  });
