import type { ZodTypeAny } from "zod";
import { z } from "zod";

export const rawLiteralsSchema = (
  arg1: ZodTypeAny,
  arg2: ZodTypeAny,
  ...args: ZodTypeAny[]
) =>
  z.object({
    raw: z.union([arg1, arg2, ...args]),
  });
