"use client";

import type { BibleChapterUniqueId } from "../../../lib";
import { bibleDeeplinkSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyBibleLinkedContent } from "./keyBibleLinkedContent";

export type UseRequestBibleLinkedContentProps = UseRequestQueryProps & {
  chapterId: BibleChapterUniqueId;
  translationId: number;
};

export const useRequestBibleLinkedContent = ({
  chapterId,
  translationId,
  ...props
}: UseRequestBibleLinkedContentProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: bibleDeeplinkSchema,
      urlBackendPath: `/bible/${translationId}/content/${chapterId}`,
    },
    queryKey: keyBibleLinkedContent({ translationId, chapterId }),
  }),
});
