import { z } from "zod";

export const addressSchema = z.object({
  street_address: z.string(),
  city: z.string(),
  region: z.string(),
  postal_code: z.string(),
  country: z.string(),
});

export type Address = z.infer<typeof addressSchema>;
