"use client";

import type { CommunityMembersSchema } from "../../../../lib";
import { communityMembersSchema } from "../../../../lib";
import type { UseRequestInfiniteQueryProps } from "../../../../request";
import { useRequestInfiniteQuery } from "../../../../request";
import { keyCommunityMembers } from "./keyCommunityMembers";

export type UseRequestCommunityMembersProps = UseRequestInfiniteQueryProps & {
  id: number;
};

export const useRequestCommunityMembers = ({
  id,
  ...props
}: UseRequestCommunityMembersProps) => ({
  infiniteQuery: useRequestInfiniteQuery({
    getNextPageParam: (
      lastPage: CommunityMembersSchema,
      allPages: CommunityMembersSchema[],
      lastPageParam: number,
    ) => (lastPage.total / (lastPageParam * 50) > 1 ? lastPageParam + 1 : null),
    initialPageParam: 0,
    ...props,
    queryFnRequestProps: ({ pageParam }) => ({
      schema: communityMembersSchema,
      urlBackendPath: `/communities/${id}/members?page=${pageParam}`,
    }),
    queryKey: keyCommunityMembers({ id }),
  }),
});
