export type SplitArrayProps<T> = {
  array: T[];
  size: number;
};

export const splitArray = <T>({ array, size }: SplitArrayProps<T>): T[][] => {
  let count = 0;
  let index = 0;

  const result = [];

  while (index < array.length)
    result[count++] = array.slice(index, (index += size));

  return result;
};
