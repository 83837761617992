import { format } from "date-fns";

export type DateFormatYYYYMMDDProps = {
  date: Date;
  separator?: "-" | "/";
};

export const dateFormatYYYYMMDD = ({
  date,
  separator = "-",
}: DateFormatYYYYMMDDProps) => format(date, `yyyy${separator}MM${separator}dd`);
