"use client";

import { useEffect, useState } from "react";

export type UseLocalStorageStateProps<T = any> = {
  defaultValue?: T;
  key: string;
};

export const useLocalStorageState = <T = any>({
  defaultValue = null,
  key,
}: UseLocalStorageStateProps<T>) => {
  const windowExists = typeof window !== "undefined";

  const [state, setState] = useState<null | T>(
    windowExists
      ? JSON.parse(
          window.localStorage.getItem(key) ?? JSON.stringify(defaultValue),
        )
      : defaultValue,
  );

  useEffect(() => {
    if (windowExists) window?.localStorage.setItem(key, JSON.stringify(state));
  }, [state]);

  return [state, setState] as const;
};
