import { z } from "zod";

export const linkSchema = z.object({
  id: z.number(),
  title: z.string(),
  url: z.string(),
  target: z.string(),
});

export const menusSchema = z.object({
  primary: z.array(linkSchema),
  "right-menu": z.array(linkSchema),
  footer: z.array(linkSchema),
});

type MenuLink = z.infer<typeof linkSchema>;

export type Menu = MenuLink[];

export type Menus = z.infer<typeof menusSchema>;
