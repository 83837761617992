import { z } from "zod";

export const searchItemTypeEnum = z.enum([
  "collection",
  "navigation",
  "page",
  "prayer",
  "radio_station",
  "setting",
]);

export type SearchItemType = z.infer<typeof searchItemTypeEnum>;
