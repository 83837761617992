import { z } from "zod";

export const paymentSchema = z.object({
  amount: z.number(),
  currency: z.string(),
  created_at: z.string().datetime(),
  status: z.string().optional().nullable(),
});

export const paymentsSchema = z.array(paymentSchema);

export type Payment = z.infer<typeof paymentSchema>;

export type Payments = Payment[];
