import type { CloudfrontCookie } from "../types";

export const signCFUrl = (
  url: string,
  cookies: Array<CloudfrontCookie>,
): string => {
  let connector = "?";
  if (url.includes(connector)) connector = "&";
  const params: string[] = [];
  if (!cookies?.length) return url;
  const cookieSet = cookies[0];
  const {
    "CloudFront-Policy": policy,
    "CloudFront-Key-Pair-Id": pair,
    "CloudFront-Signature": signature,
  } = cookieSet;
  //const expiration = Date.now() + 86000; // 400 less to allow for space between retrieval and signing
  params.push(`Policy=${policy}`);
  params.push(`Signature=${signature}`);
  params.push(`Key-Pair-Id=${pair}`);
  return `${url}${connector}${params.join("&")}`;
};
