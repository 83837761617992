"use client";

import { useLocale } from "@packages/i18n";
import { useQueryClient } from "@tanstack/react-query";
import { z } from "zod";

import type { AvatarSchema, Me } from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyAvatars } from "../avatars";
import { keyMe } from "./keyMe";
import { requestMeProps } from "./requestMe";

export const useRequestMe = () => {
  const locale = useLocale();
  const queryClient = useQueryClient();

  return {
    mutationAvatar: useRequestMutation({
      mutationFnRequestProps: (data: Pick<AvatarSchema, "id">) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/me/avatar",
      }),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [keyAvatars(), locale] });
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] });
      },
    }),
    mutationDelete: useRequestMutation({
      mutationFnRequestProps: {
        method: "DELETE",
        schema: z.boolean(),
        urlBackendPath: "/me",
      },
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] }),
    }),
    mutationEmail: useRequestMutation({
      mutationFnRequestProps: (data: Pick<Me, "email">) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/me/email",
      }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] }),
    }),
    mutationPassword: useRequestMutation({
      mutationFnRequestProps: (data: {
        current_password: string;
        new_password: string;
      }) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/me/password",
      }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] }),
    }),
    mutationPhone: useRequestMutation({
      mutationFnRequestProps: (data: { otp: string; phone: string }) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/me/phone",
      }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] }),
    }),
    mutationPut: useRequestMutation({
      mutationFnRequestProps: (data: Partial<Me>) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/me",
      }),
      onSuccess: () =>
        queryClient.invalidateQueries({ queryKey: [keyMe(), locale] }),
    }),
    query: useRequestQuery({
      queryFnRequestProps: requestMeProps,
      queryKey: keyMe(),
    }),
  };
};
