import { meSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export const requestMeProps = {
  schema: meSchema,
  urlBackendPath: "/me",
};

export type RequestMeProps = Omit<RequestProps, "url">;

export const requestMe = ({ ...props }: RequestMeProps = {}) =>
  request({
    ...props,
    ...requestMeProps,
  });
