export enum HallowCookies {
  ANON_SEGMENT_ID = "ajs_anonymous_id",
  API_ENDPOINT = "hlwapi",
  CONSENT = "hlwconsent",
  LD_GUEST_KEY = "hlwldguestkey",
  REFRESH = "hlwrfh",
  SESSION = "hlwsess",
  TIMEZONE = "hlwtz",
}

export default HallowCookies;
