import type { BaseKeyFunction } from "../../../../../lib";

export type KeyCommunitiesAdminFlaggableProps = {
  hasStaleTime?: boolean;
  id: number;
};

export const keyCommunitiesAdminFlaggable: BaseKeyFunction<
  KeyCommunitiesAdminFlaggableProps
> = ({ hasStaleTime, id }) => [
  "communities-admin-flaggable",
  id?.toString(),
  `has-stale-time-${hasStaleTime ?? false}`,
];
