"use client";

import { nextUpSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyNextUp } from "./keyNextUp";

export type UseRequestNextUpProps = UseRequestQueryProps;

export const useRequestNextUp = ({ ...props }: UseRequestNextUpProps = {}) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: nextUpSchema,
      urlBackendPath: "/nextup/v2",
    },
    queryKey: keyNextUp(),
  }),
});
