import { z } from "zod";

import { communitySchema } from "../communitySchema";

export const communitiesAdminCommunitiesSchema = z.object({
  total: z.number(),
  results: z.array(communitySchema),
});

export type CommunitiesAdminCommunities = z.infer<
  typeof communitiesAdminCommunitiesSchema
>;
