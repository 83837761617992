export type ConsoleErrorPackagesSdkProps = {
  error: string;
  heading: string;
};

export const consoleErrorPackagesSdk = ({
  error,
  heading,
}: ConsoleErrorPackagesSdkProps) =>
  console.error(`[@packages/sdk] ${heading}:\n\t${JSON.stringify(error)}`);
