import { communitiesAdminCommunitiesSchema } from "../../../../lib";
import type { RequestProps } from "../../../../request";
import { request } from "../../../../request";

export const requestCommunitiesAdminCommunitiesProps = {
  schema: communitiesAdminCommunitiesSchema,
  urlBackendPath: "/me/communities/admin",
};

export type RequestCommunitiesAdminCommunitiesProps = RequestProps;

export const requestCommunitiesAdminCommunities = ({ ...props } = {}) =>
  request({
    ...props,
    ...requestCommunitiesAdminCommunitiesProps,
  });
