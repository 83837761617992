import { z } from "zod";

import { routineItemSchema } from "./routineItemSchema";

export const routineSchema = z.object({
  id: z.number(),
  items: routineItemSchema.array(),
  send_notification: z.boolean(),
  timestamp: z.string(),
});

export type Routine = z.infer<typeof routineSchema>;
