"use client";

import { pagesSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyPages } from "./keyPages";

export type UseRequestPagesProps = UseRequestQueryProps & {
  page: string;
};

export const useRequestPages = ({ page, ...props }: UseRequestPagesProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: pagesSchema,
      urlBackendPath: `/pages/${page}`,
    },
    queryKey: keyPages({ page }),
  }),
});
