"use client";

import { transcriptSchema } from "../../../lib";
import { useRequestQuery } from "../../../request";
import { keyTranscript } from "./keyTranscript";

export type UseRequestTranscriptProps = {
  id: number;
};

export const useRequestTranscript = ({ id }: UseRequestTranscriptProps) => ({
  query: useRequestQuery({
    enabled: typeof id === "number",
    queryFnRequestProps: {
      schema: transcriptSchema,
      urlBackendPath: `/audio/${id}/transcript`,
    },
    queryKey: keyTranscript({ id }),
  }),
});
