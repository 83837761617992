import { trustedPrayerSchema } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestPrayerTrustedProps = Partial<RequestProps> & {
  id: number;
};

export const requestPrayerTrusted = ({ id }: RequestPrayerTrustedProps) =>
  request({
    next: { tags: [`prayer-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: trustedPrayerSchema,
    urlBackendPath: `/trusted/prayers/${id}`,
  });
