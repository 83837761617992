import { z } from "zod";

import { rawLiteralsSchema } from "./rawLiteralsSchema";
import { rawNumberSchema } from "./rawNumberSchema";
import { rawStringSchema } from "./rawStringSchema";
import { searchItemMetaSchema } from "./searchItemMetaSchema";

export const bibleSearchItemSchema = z.object({
  reference: rawStringSchema.optional(),
  translation_id: rawNumberSchema,
  deeplink: rawStringSchema,
  verse_id: rawNumberSchema.optional(),
  text: z
    .object({
      snippet: z.string().nullable(),
      raw: z.string(),
    })
    .optional(),
  book_id: rawStringSchema,
  chapter_id: rawNumberSchema.optional(),
  _meta: searchItemMetaSchema,
  id: rawStringSchema,
  type: rawLiteralsSchema(
    z.literal("book"),
    z.literal("chapter"),
    z.literal("verse"),
  ),
  book_name: rawStringSchema,
});

export type BibleSearchItemSchema = z.infer<typeof bibleSearchItemSchema>;
