import { z } from "zod";

export const imagesSchema = z.object({
  small: z.string().url().nullable(),
  medium: z.string().url().nullable(),
  large: z.string().url().nullable(),
  fallback: z.string().url().nullable(),
  color_hex: z
    .string()
    .regex(/#[0-9A-Fa-f]{6,8}/)
    .nullable()
    .optional(),
});

export type Images = z.infer<typeof imagesSchema>;
