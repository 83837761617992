"use client";

import { communitiesAdminMostPopularPrayersSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminMostPopularPrayers } from "./keyCommunitiesAdminMostPopularPrayers";

export type UseRequestCommunitiesAdminMostPopularPrayersProps =
  UseRequestQueryProps & {
    id: number;
  };

export const useRequestCommunitiesAdminMostPopularPrayers = ({
  id,
  ...props
}: UseRequestCommunitiesAdminMostPopularPrayersProps) => ({
  ...props,
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: communitiesAdminMostPopularPrayersSchema,
      urlBackendPath: `/communities/${id}/stats/most-popular-prayers`,
    },
    queryKey: keyCommunitiesAdminMostPopularPrayers({ id }),
  }),
});
