"use client";

import {
  communitiesAdminGraphSchema,
  dateFormatYYYYMMDD,
} from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminGraph } from "./keyCommunitiesAdminGraph";

export type UseRequestCommunitiesAdminGraphProps = UseRequestProps & {
  endDate?: Date;
  id: number;
  startDate?: Date;
};

export const useRequestCommunitiesAdminGraph = ({
  endDate,
  id,
  startDate,
  ...props
}: UseRequestCommunitiesAdminGraphProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: communitiesAdminGraphSchema,
      urlBackendPath: `/communities/${id}/stats/completed-prayers-over-time?${startDate ? `start_date=${dateFormatYYYYMMDD({ date: startDate })}` : ""}${endDate ? `&end_date=${dateFormatYYYYMMDD({ date: endDate })}` : ""}`,
    },
    queryKey: keyCommunitiesAdminGraph({ endDate, id, startDate }),
  }),
});
