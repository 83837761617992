"use client";

import type {
  QueryFunctionContext,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { z, ZodSchema } from "zod";

import { useSession } from "../../session";
import { request, type RequestProps } from "../request";
import { useHeadersValues } from "./useHeadersValues";

export type UseRequestQueryProps<SchemaType extends ZodSchema = any> = Omit<
  Partial<UseQueryOptions<z.TypeOf<SchemaType>>>,
  "queryFn"
> & {
  enabledWithoutSessionToken?: boolean;
  queryFnRequestProps?:
    | RequestProps<SchemaType>
    | ((props: QueryFunctionContext) => RequestProps<SchemaType>);
};

export const useRequestQuery = <SchemaType extends ZodSchema = any>({
  enabled = true,
  enabledWithoutSessionToken = false,
  queryKey,
  queryFnRequestProps,
  ...props
}: UseRequestQueryProps<SchemaType>) => {
  const headersValues = useHeadersValues();
  const { token, refresh } = useSession();

  return useQuery({
    enabled:
      (enabledWithoutSessionToken ? true : typeof token === "string") &&
      enabled,
    queryFn: (props) =>
      request({
        ...props,
        headersValues: headersValues.current,
        refresh,
        ...(typeof queryFnRequestProps === "function"
          ? queryFnRequestProps(props)
          : queryFnRequestProps),
      }),
    queryKey: [queryKey, headersValues.current.locale],
    ...props,
  });
};
