import type { FlagRecord } from "../../../lib";
import type { UseRequestMutationProps } from "../../../request";
import { useRequestMutation } from "../../../request";

type UseRequestFlagRecordVariables = { data: FlagRecord };

export type UseRequestFlagRecordProps = UseRequestMutationProps<
  any,
  UseRequestFlagRecordVariables
>;

export const useRequestFlagRecord = ({
  ...props
}: UseRequestFlagRecordProps = {}) => ({
  mutationPost: useRequestMutation({
    ...props,
    mutationFnRequestProps: (data: UseRequestFlagRecordVariables) => ({
      body: JSON.stringify(data),
      urlBackendPath: "/moderation/flag-record",
    }),
  }),
});
