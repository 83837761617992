"use client";

import {
  cancelSubscriptionSchema,
  productsSchema,
  refundPaymentSchema,
  subscriptionDetailSchema,
  subscriptionSchema,
  subscriptionsSchema,
} from "../../../lib";
import { useRequestMutation, useRequestQuery } from "../../../request";
import { keyPlans } from "./keyPlans";
import { keySubscription, keySubscriptions } from "./keySubscriptions";

type SubscriptionPaymentInfo = {
  token: string;
};

type SubscriptionChangePlan = {
  price_id: string;
};

type SubscriptionRefund = {
  subscription_id: number;
};

export const useRequestSubscription = () => {
  return {
    query: useRequestQuery({
      queryFnRequestProps: {
        schema: subscriptionDetailSchema,
        urlBackendPath: "/subscription",
      },
      queryKey: keySubscription(),
    }),
    queryAll: useRequestQuery({
      queryFnRequestProps: {
        schema: subscriptionsSchema,
        urlBackendPath: "/me/subscriptions",
      },
      queryKey: keySubscriptions(),
    }),
    queryProducts: useRequestQuery({
      queryFnRequestProps: {
        schema: productsSchema,
        urlBackendPath: "/products/plans",
      },
      queryKey: keyPlans(),
    }),
    mutationPaymentInfo: useRequestMutation({
      mutationFnRequestProps: (data: SubscriptionPaymentInfo) => ({
        body: JSON.stringify(data),
        method: "PUT",
        urlBackendPath: "/subscription/payment",
      }),
    }),
    mutationChangePlan: useRequestMutation({
      mutationFnRequestProps: (data: SubscriptionChangePlan) => ({
        body: JSON.stringify(data),
        method: "PUT",
        schema: subscriptionSchema,
        urlBackendPath: "/subscription",
      }),
    }),
    mutationCancel: useRequestMutation({
      mutationFnRequestProps: {
        method: "PUT",
        schema: cancelSubscriptionSchema,
        urlBackendPath: "/subscription/cancel",
      },
    }),

    mutationRefund: useRequestMutation({
      mutationFnRequestProps: (data: SubscriptionRefund) => ({
        body: JSON.stringify(data),
        method: "PUT",
        schema: refundPaymentSchema,
        urlBackendPath: "/subscription/refund",
      }),
    }),

    mutationRestart: useRequestMutation({
      mutationFnRequestProps: (data: SubscriptionRefund) => ({
        body: JSON.stringify(data),
        method: "PUT",
        schema: subscriptionSchema,
        urlBackendPath: "/subscription/restart",
      }),
    }),
  };
};
