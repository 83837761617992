import { z } from "zod";

import { basePrayerInCollectionSchema } from "../prayer";
import { baseCollectionSchema } from "./baseCollectionSchema";

export const collectionSchema = baseCollectionSchema.extend({
  is_completed: z.boolean(),
  has_joined: z.boolean().nullable(),
  prayers: z.array(
    basePrayerInCollectionSchema.omit({
      guides: true,
      order: true,
    }),
  ),
});

export type Collection = z.infer<typeof collectionSchema>;
