import { trustedCollectionSchema } from "../../../lib";
import { request } from "../../../request";

export type RequestCollectionTrustedProps = {
  id: number;
  type?: "collection" | "chapter" | "challenge";
};

export const requestCollectionTrusted = ({
  id,
  type = "collection",
}: RequestCollectionTrustedProps) =>
  request({
    next: { tags: [`collection-${id}`], revalidate: 60 * 60 },
    requestType: "trusted",
    schema: trustedCollectionSchema,
    urlBackendPath: `/trusted/collections/${id}${type !== "collection" ? `?type=${type}` : ""}`,
  });
