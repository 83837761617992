import { z } from "zod";

import { bibleSearchItemSchema } from "./bibleSearchItemSchema";
import { searchMetaSchema } from "./searchMetaSchema";

export const bibleSearchResultSchema = z.object({
  meta: searchMetaSchema,
  results: bibleSearchItemSchema.array(),
});

export type BibleSearchResultSchema = z.infer<typeof bibleSearchResultSchema>;
