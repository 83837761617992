import { z } from "zod";

export const trackSchema = z.object({
  duration: z.number(),
  guide_id: z.number(),
  id: z.number(),
  length: z.string(),
  paid: z.boolean(),
  prayer_id: z.number(),
});

export type Track = z.infer<typeof trackSchema>;
