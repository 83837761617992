"use client";

import type { UseRequestProps } from "../../../../request";
import { useRequest } from "../../../../request";

export type UseRequestQueueCurrentItemIndexProps = UseRequestProps;

export const useRequestQueueCurrentItemIndex = ({
  ...props
}: UseRequestQueueCurrentItemIndexProps = {}) => ({
  requestPost: useRequest({
    fnRequestProps: (data: { current_item_index: number }) => ({
      body: JSON.stringify(data),
      method: "PUT",
      urlBackendPath: "/queue/current-item-index",
    }),
  }),
});
