import { z } from "zod";

import { searchItemSchema } from "./searchItemSchema";
import { searchMetaSchema } from "./searchMetaSchema";

export const searchResultSchema = z.object({
  meta: searchMetaSchema,
  results: searchItemSchema.array(),
});

export type SearchResultSchema = z.infer<typeof searchResultSchema>;
