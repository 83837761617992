import { z } from "zod";

import { priceSchema } from "./priceSchema";
import { productSchema } from "./productSchema";

export const subscriptionSchema = z.object({
  billing_issues_at: z.string().nullable(),
  display_metadata: z.object({
    title: z.string(),
    description: z.string(),
    details: z.string(),
  }),
  end_date: z.string().datetime(),
  id: z.number(),
  is_owner: z.boolean(),
  is_renewing: z.boolean(),
  is_trial: z.boolean(),
  latest_receipt: z.string().nullable(),
  price_id: z.string().nullable(),
  product: z.string(),
  promo: z.string().nullable(),
  start_date: z.string().datetime(),
  trial_ends_at: z.string().nullable(),
  type: z.string(),
  user_id: z.number(),
});

export const subscriptionsSchema = subscriptionSchema.array();

export type Subscription = z.infer<typeof subscriptionSchema>;

export const subscriptionDetailSchema = subscriptionSchema.extend({
  plan: productSchema,
  price: priceSchema,
});

export type SubscriptionDetail = z.infer<typeof subscriptionDetailSchema> &
  Subscription;

export const cancelSubscriptionSchema = z.object({
  is_refund_eligible: z.boolean(),
  subscription: subscriptionSchema,
});

export type CancelSubscriptionResponse = z.infer<
  typeof cancelSubscriptionSchema
>;

export const refundPaymentSchema = z.object({
  amount: z.number(),
  currency: z.string(),
  status: z.enum(["pending", "settled", "failed"]),
  created_at: z.string().datetime(),
});

export type RefundPaymentResponse = z.infer<typeof refundPaymentSchema>;
