import { z } from "zod";

import { collectionSchema } from "../collection";
import { guideSchema } from "../guideSchema";
import { playableAudio } from "../playableAudioSchema";
import { basePrayerSchema } from "../prayer";
import { trackSchema } from "../trackSchema";

export const queueItemSchema = z.object({
  collection: collectionSchema.omit({
    prayers: true,
  }),
  prayer: basePrayerSchema.extend({
    tracks: z.array(trackSchema),
    guides: z.array(guideSchema),
  }),
  selected_audio: playableAudio,
});

export type QueueItem = z.infer<typeof queueItemSchema>;
