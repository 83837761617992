import { z } from "zod";

export const communityMemberSummarySchema = z.object({
  member_count: z.number(),
  image_urls: z.array(z.string()),
  is_member: z.boolean(),
});

export type CommunityMemberSummary = z.infer<
  typeof communityMemberSummarySchema
>;
