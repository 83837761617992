import { z } from "zod";

export const priceSchema = z.object({
  currency: z.string(),
  amount: z.number(),
  region: z.string(),
  id: z.string(),
});

export type Price = z.infer<typeof priceSchema>;
