import { z } from "zod";

import { queueItemSchema } from "./queueItemSchema";

export const queueManagedItemSchema = queueItemSchema.extend({
  uuid: z.string(),
  errored: z.boolean(),
});

export type QueueManagedItem = z.infer<typeof queueManagedItemSchema>;
