import type { ZodSchema } from "zod";
import { z } from "zod";

import { consoleErrorPackagesSdk } from "./consoleErrorPackagesSdk";

export type ValidatePromiseSchemaProps<SchemaType extends ZodSchema = any> = {
  promise: Promise<any>;
  schema?: SchemaType;
};

export const validatePromiseSchema = <SchemaType extends ZodSchema = any>({
  promise,
  schema = z.any() as any,
}: ValidatePromiseSchemaProps<SchemaType>): Promise<z.infer<SchemaType>> =>
  process.env.NODE_ENV === "production"
    ? Promise.resolve(promise).then((data) => {
        const safeParse = schema.safeParse(data);

        if (safeParse.error) {
          consoleErrorPackagesSdk({
            error: safeParse.error.toString(),
            heading: `${safeParse.error.name}: ${safeParse.error.toString()}`,
          });
        }

        // in production, we give back the data even if it doesn't match
        return safeParse.data ?? data;
      })
    : Promise.resolve(promise)
        .then((data) => schema.parse(data))
        .catch((error) => {
          consoleErrorPackagesSdk({
            error,
            heading: "Failed schema validation",
          });
          throw error;
        });
