import { z } from "zod";

import { bibleLanguageList } from "./bibleLanguageList";

export const bibleManifestSchema = z.object({
  translation_id: z.number(),
  translation_name: z.string(),
  translation_language: bibleLanguageList,
  translation_description: z.string(),
  translation_copyright: z.string().nullable().optional(),
  translation_short_copyright: z.string().nullable().optional(),
  translation_version: z.string(),
  books: z
    .object({
      id: z.union([
        z.literal("Gen"),
        z.literal("Exo"),
        z.literal("Lev"),
        z.literal("Num"),
        z.literal("Deu"),
        z.literal("Jos"),
        z.literal("Jdg"),
        z.literal("Rut"),
        z.literal("1Sa"),
        z.literal("2Sa"),
        z.literal("1Ki"),
        z.literal("2Ki"),
        z.literal("1Ch"),
        z.literal("2Ch"),
        z.literal("Ezr"),
        z.literal("Neh"),
        z.literal("Tob"),
        z.literal("Jdt"),
        z.literal("Est"),
        z.literal("Job"),
        z.literal("Psa"),
        z.literal("1Ma"),
        z.literal("2Ma"),
        z.literal("Pro"),
        z.literal("Ecc"),
        z.literal("Sng"),
        z.literal("Wis"),
        z.literal("Sir"),
        z.literal("Isa"),
        z.literal("Jer"),
        z.literal("Lam"),
        z.literal("Bar"),
        z.literal("Ezk"),
        z.literal("Dan"),
        z.literal("Hos"),
        z.literal("Jol"),
        z.literal("Ams"),
        z.literal("Oba"),
        z.literal("Jon"),
        z.literal("Mic"),
        z.literal("Nah"),
        z.literal("Hab"),
        z.literal("Zep"),
        z.literal("Hag"),
        z.literal("Zec"),
        z.literal("Mal"),
        z.literal("Mat"),
        z.literal("Mar"),
        z.literal("Luk"),
        z.literal("Jhn"),
        z.literal("Act"),
        z.literal("Rom"),
        z.literal("1Co"),
        z.literal("2Co"),
        z.literal("Gal"),
        z.literal("Eph"),
        z.literal("Php"),
        z.literal("Col"),
        z.literal("1Th"),
        z.literal("2Th"),
        z.literal("1Ti"),
        z.literal("2Ti"),
        z.literal("Tit"),
        z.literal("Phm"),
        z.literal("Heb"),
        z.literal("Jam"),
        z.literal("1Pe"),
        z.literal("2Pe"),
        z.literal("1Jo"),
        z.literal("2Jo"),
        z.literal("3Jo"),
        z.literal("Jud"),
        z.literal("Rev"),
      ]),
      numeric_id: z.number().nullable().optional(),
      name: z.string(),
      testament: z.union([z.literal("old"), z.literal("new")]),
      chapter_count: z.number(),
      long_name: z.string().nullable().optional(),
    })
    .array(),
});

export type BibleManifest = z.infer<typeof bibleManifestSchema>;
export type BibleBook = BibleManifest["books"][0];
export type BibleBookId = BibleBook["id"];
export type BibleChapterUniqueId = `${BibleBookId}:${number}`;
