import Cookies from "js-cookie";
import type {
  RequestCookies,
  ResponseCookies,
} from "next/dist/compiled/@edge-runtime/cookies";
import type { ReadonlyRequestCookies } from "next/dist/server/web/spec-extension/adapters/request-cookies";

import type { CookieKey } from "../types";

export type DeleteCookieProps = {
  cookies?: ReadonlyRequestCookies | RequestCookies | ResponseCookies;
  key: CookieKey;
};

export const deleteCookie = ({ cookies, key }: DeleteCookieProps) =>
  cookies ? cookies.delete(key) : Cookies.remove(key);
