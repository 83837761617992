"use client";

import type { DisplayType } from "../../../lib";
import { sectionSchema } from "../../../lib";
import type { UseRequestQueryProps } from "../../../request";
import { useRequestQuery } from "../../../request";
import { keyFavorites } from "./keyFavorites";

export type UseRequestFavoritesProps = UseRequestQueryProps & {
  displayType: DisplayType;
};

export const useRequestFavorites = ({
  displayType,
  ...props
}: UseRequestFavoritesProps) => ({
  ...props,
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: sectionSchema,
      urlBackendPath: `/me/favorites${displayType === "sessions" ? "?format=prayer" : ""}`,
    },
    queryKey: keyFavorites({ displayType }),
  }),
});
