import { z } from "zod";

import { communityStatusEnum, communityTypeEnum } from "../../enums";
import { addressSchema } from "../addressSchema";
import { communityMemberSummarySchema } from "./communityMemberSummarySchema";
import { communitySharingDefaultsSchema } from "./communitySharingDefaultsSchema";

export const communitySchema = z.object({
  id: z.number(),
  code: z.string().nullable(),
  description: z.string().nullable(),
  image_url: z.string().nullable(),
  color_hex: z.string().nullable(),
  address: addressSchema.nullable(),
  name: z.string(),
  status: communityStatusEnum.nullable(),
  launched_at: z.string().datetime().nullable(),
  type: communityTypeEnum,
  created_at: z.string().datetime(),
  updated_at: z.string().datetime(),
  is_non_admin_content_allowed: z.boolean().nullable().optional(),
  address_label: z.string(),
  simple_address_label: z.string(),
  member_summary: communityMemberSummarySchema,
  is_nonadmin_content_allowed: z.boolean(),
  sharing_defaults: communitySharingDefaultsSchema,
  is_admin: z.boolean(),
  has_requested_admin: z.boolean(),
  parent_community_id: z.number().nullable(),
  tag_text: z.string().nullable(),
});

export type Community = z.infer<typeof communitySchema>;
