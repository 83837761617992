import { z } from "zod";

import { collectionSchema } from "../collection";
import { guideSchema } from "../guideSchema";
import { trackSchema } from "../trackSchema";
import { basePrayerSchema } from "./basePrayerSchema";

export const prayerSchema = basePrayerSchema.extend({
  tracks: z.array(trackSchema),
  guides: z.array(guideSchema),
  selected_track_id: z.number().nullable(),
  selected_guide_id: z.number(),
  show_guide_selection: z.boolean(),
  challenge_id: z.number().nullable(),
  collection: z.lazy(() =>
    collectionSchema.pick({
      desc: true,
      has_access: true,
      id: true,
      is_album: true,
      is_completed: true,
      paid: true,
      prayers_count: true,
      sessions: true,
      short_desc: true,
      style_id: true,
      supertitle: true,
      title: true,
    }),
  ),
});

export type Prayer = z.infer<typeof prayerSchema>;
