export type GetDeeplinkProps = {
  string: string;
};

export const getDeeplink = ({ string }: GetDeeplinkProps) => {
  if (string.includes("hallow://meditate/"))
    return `/sections/${string.split("hallow://meditate/")[1]}`;

  if (string.includes("hallow://sleep/"))
    return `/sections/${string.split("hallow://sleep/")[1]}`;

  return `/pages/${string.split("hallow://pages/")[1]}`;
};
