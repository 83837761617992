"use client";

import type { QueryClientProviderProps } from "@tanstack/react-query";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { useState } from "react";

import { useSession } from "../../session";

export type DataProviderProps = Omit<QueryClientProviderProps, "client">;

export const DataProvider = ({ children, ...props }: DataProviderProps) => {
  const { refresh } = useSession();

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus:
              process.env.NODE_ENV === "development" ? false : true,
          },
        },
        mutationCache: new MutationCache({
          onError: async (error: any, variables, context, mutation) => {
            if (error?.status === 401)
              await refresh({ callback: () => mutation.execute(variables) });
          },
        }),
        queryCache: new QueryCache({
          onError: async (error: any, query) => {
            if (error?.status === 401)
              await refresh({ callback: () => query.fetch() });
          },
        }),
      }),
  );

  return (
    <QueryClientProvider client={queryClient} {...props}>
      {children}
    </QueryClientProvider>
  );
};
