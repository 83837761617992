"use client";

import type { ZodSchema } from "zod";

import { useSession } from "../../session";
import type { RequestProps } from "../request";
import { request } from "../request";
import { useHeadersValues } from "./useHeadersValues";

export type UseRequestProps<
  SchemaType extends ZodSchema = any,
  VariablesType = void,
> = {
  fnRequestProps?:
    | RequestProps<SchemaType>
    | ((props: VariablesType) => RequestProps<SchemaType>);
};

export const useRequest = <
  SchemaType extends ZodSchema = any,
  VariablesType = void,
>({
  fnRequestProps,
}: UseRequestProps<SchemaType, VariablesType>) => {
  const headersValues = useHeadersValues();
  const { refresh } = useSession();

  return {
    request: (props: VariablesType) =>
      request({
        ...props,
        headersValues: headersValues.current,
        refresh,
        ...(typeof fnRequestProps === "function"
          ? fnRequestProps(props)
          : fnRequestProps),
      }),
  };
};
