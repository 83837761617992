import { z } from "zod";

import { sectionItemTypeEnum } from "../../enums";
import { sectionTypeEnum } from "../../enums/sectionTypeEnum";
import { sectionItemSchema } from "./sectionItemSchema";

export const sectionSchema = z.object({
  action_link: z.string().optional().nullable(),
  action_title: z.string().optional().nullable(),
  id: z.number(),
  is_first_large: z.boolean().optional(),
  language: z.string().optional(),
  page_id: z.number().optional(),
  title: z.string().nullable(),
  type: sectionTypeEnum,
  item_type: sectionItemTypeEnum,
  items: z.array(sectionItemSchema),
});

export type Section = z.infer<typeof sectionSchema>;
