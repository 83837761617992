import type { CookieKey } from "./types";
import type {
  DeleteCookieProps,
  GetCookieProps,
  SetCookieProps,
} from "./utils";
import { deleteCookie, getCookie, setCookie } from "./utils";

export const COOKIE_KEY_REFRESH_TOKEN: CookieKey = "hlwrfh";

export type DeleteCookieRefreshTokenProps = Omit<DeleteCookieProps, "key">;

export const deleteCookieRefreshToken = ({
  ...props
}: DeleteCookieRefreshTokenProps = {}) =>
  deleteCookie({ key: COOKIE_KEY_REFRESH_TOKEN, ...props });

export type GetCookieRefreshTokenProps = Omit<GetCookieProps, "key">;

export const getCookieRefreshToken = ({
  ...props
}: GetCookieRefreshTokenProps = {}) =>
  getCookie({ key: COOKIE_KEY_REFRESH_TOKEN, ...props });

export type SetCookieRefreshTokenProps = Omit<SetCookieProps, "key">;

export const setCookieRefreshToken = ({
  ...props
}: SetCookieRefreshTokenProps) =>
  setCookie({ key: COOKIE_KEY_REFRESH_TOKEN, ...props });
