import { z } from "zod";

import { flaggableObjectWithRecordsSchema } from "../../moderation";

export const communitiesAdminFlaggableSchema = z.object({
  total: z.number(),
  results: z.array(flaggableObjectWithRecordsSchema),
});

export type CommunitiesAdminFlaggable = z.infer<
  typeof communitiesAdminFlaggableSchema
>;
