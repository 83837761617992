"use client";

import { communitiesAdminStatsSchema } from "../../../../lib";
import type { UseRequestQueryProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyCommunitiesAdminStats } from "./keyCommunitiesAdminStats";

export type UseRequestCommunitiesAdminStatsProps = UseRequestQueryProps & {
  id: number;
};

export const useRequestCommunitiesAdminStats = ({
  id,
  ...props
}: UseRequestCommunitiesAdminStatsProps) => ({
  query: useRequestQuery({
    ...props,
    queryFnRequestProps: {
      schema: communitiesAdminStatsSchema,
      urlBackendPath: `/communities/${id}/stats/totals`,
    },
    queryKey: keyCommunitiesAdminStats({ id }),
  }),
});
