import { z } from "zod";

export const communitySharingDefaultsSchema = z.object({
  intentions: z.boolean(),
  prompt_responses: z.boolean(),
});

export type CommunitySharingDefaults = z.infer<
  typeof communitySharingDefaultsSchema
>;
