"use client";

import { basePrayerSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequest } from "../../../../request";

export type UseRequestCollectionNextUpProps = UseRequestProps;

export const useRequestCollectionNextUp = ({
  ...props
}: UseRequestCollectionNextUpProps = {}) => ({
  requestGet: useRequest({
    ...props,
    fnRequestProps: ({ id }: { id: number }) => ({
      schema: basePrayerSchema,
      urlBackendPath: `/collections/${id}/nextup`,
    }),
  }),
});
