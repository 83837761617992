const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const trim = (
  str: string,
  trim: string = " ",
  start: boolean = true,
  end: boolean = true,
) => {
  if (trim === " ") {
    if (end && start) return str.trim();
    if (start) return str.trimStart();
    return str.trimEnd();
  }
  trim = escapeRegExp(trim);
  const startRe = new RegExp(`^(?:${trim})+`, "g");
  const endRe = new RegExp(`(?:${trim})+$`, "g");
  if (start) str = str.replaceAll(startRe, "");
  if (end) str = str.replaceAll(endRe, "");
  return str;
};
