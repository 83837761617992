import type { BaseKeyFunction } from "../../../lib";

export type KeyPrayerProps = {
  id: number;
};

export const keyPrayer: BaseKeyFunction<KeyPrayerProps> = ({ id }) => [
  "prayer",
  id?.toString(),
];
