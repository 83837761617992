import type { BaseKeyFunction } from "../../../../lib";

export type KeyCommunitiesAdminGraphProps = {
  endDate?: Date;
  id: number;
  startDate?: Date;
};

export const keyCommunitiesAdminGraph: BaseKeyFunction<
  KeyCommunitiesAdminGraphProps
> = ({ endDate, id, startDate }) => [
  "communities-admin-graph",
  id?.toString(),
  startDate?.toString(),
  endDate?.toString(),
];
