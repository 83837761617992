import { z } from "zod";

import { imagesSchema } from "../imagesSchema";

export const basePrayerSchema = z.object({
  id: z.number(),
  collection_id: z.number(),
  detailed_desc: z.string(),
  is_available: z.boolean(),
  is_download_enabled: z.boolean(),
  is_song: z.boolean(),
  label_desc: z.string(),
  lengths: z.string().nullable(),
  shareable_quote: z.string().nullable(),
  shareable_url: z.string(),
  supertitle: z.string(),
  title: z.string(),
  order: z.number().nullable(),
  prompt: z.string().nullable(),
  has_access: z.boolean(),
  is_completed: z.boolean(),
  is_favorite: z.boolean(),
  is_redeemed: z.boolean(),
  images: imagesSchema,
});

export type BasePrayer = z.infer<typeof basePrayerSchema>;
