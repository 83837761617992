import Cookies from "js-cookie";
import { z } from "zod";

import { HallowCookies } from "../constants";

export const deleteCookiesHlwLdGuestKey = () =>
  Cookies.remove("hlwldguestkey", { domain: ".hallow.com" });

export const getCookiesHlwConsent = () => Cookies.get("hlwconsent");

export const getValidatedCookie = <SchemaType>(
  key: string,
  schema: z.ZodSchema<SchemaType>,
  isJson: boolean = true,
): SchemaType | null => {
  const rawCookie = Cookies.get(key);
  if (!rawCookie) return null;

  try {
    let parsedCookie = rawCookie;
    if (isJson) parsedCookie = JSON.parse(rawCookie);
    const result = schema.parse(parsedCookie);
    return result;
  } catch (error) {
    console.error(`Failed to validate ${key} cookie:`, error);
    return null;
  }
};

const ConsentSchema = z.array(z.number());

export const getValidatedCookiesAnonSegmentId = () => {
  return getValidatedCookie(HallowCookies.ANON_SEGMENT_ID, z.string(), false);
};

export const getValidatedCookiesHlwConsent = () => {
  return getValidatedCookie(HallowCookies.CONSENT, ConsentSchema);
};

/**
 * This won't work if we ever get international domains with a TLD with >1 segment (e.g., .co.uk)
 */
export const getCookieDomain = (hostname) => {
  const hostArray = hostname.split(".");
  const len = hostArray.length;

  if (len === 1) return hostArray[0];
  return `${hostArray[len - 2]}.${hostArray[len - 1]}`;
};

export const setCookiesHlwConsent = (value: string) => {
  Cookies.withConverter({
    write: function (value) {
      return value;
    },
  }).set(HallowCookies.CONSENT, value, {
    domain: getCookieDomain(
      typeof window !== "undefined" ? window.location.hostname : "",
    ),
    expires: new Date("1/1/2060"),
    path: "/",
  });
};

export const getValidatedCookiesHighPrivacyZone = () => {
  return getValidatedCookie("hlwhighprivacy", z.boolean());
};

export const setCookiesHighPrivacyZone = (value: string) => {
  Cookies.withConverter({
    write: function (value) {
      return value;
    },
  }).set("hlwhighprivacy", value);
};

export const getValidatedCookiesLdGuestKey = () => {
  return getValidatedCookie(HallowCookies.LD_GUEST_KEY, z.string(), false);
};
