"use client";

import type { DisplayType } from "../../../../lib";
import { sectionSchema } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequestQuery } from "../../../../request";
import { keyMeRecents } from "./keyMeRecents";

export type UseRequestMeRecentsProps = UseRequestProps & {
  displayType: DisplayType;
};

export const useRequestMeRecents = ({
  displayType,
  ...props
}: UseRequestMeRecentsProps) => ({
  ...props,
  query: useRequestQuery({
    queryFnRequestProps: {
      schema: sectionSchema,
      urlBackendPath: `/me/recents${displayType === "sessions" ? "?format=prayer" : ""}`,
    },
    queryKey: keyMeRecents({ displayType }),
  }),
});
