import {
  addMonths,
  differenceInCalendarDays,
  isFuture,
  isPast,
  subDays,
} from "date-fns";

import type { PlanPeriod, Product, Subscription } from "../types";

export const isMobileSubscription = (subscription?: Subscription): boolean =>
  isApple(subscription) || isGoogle(subscription) || isAmazon(subscription);

export const isWebSubscription = (subscription?: Subscription): boolean => {
  return isStripe(subscription) || isPayPal(subscription);
};

export const isFree = (subscription?: Subscription): boolean => {
  return subscription?.type === "hallow";
};

export const isLifetime = (subscription?: Subscription): boolean => {
  return subscription.product === "lifetime_full_subscription";
};

export const isStripe = (subscription?: Subscription): boolean => {
  return subscription?.type === "stripe";
};

export const isPayPal = (subscription?: Subscription): boolean => {
  return subscription?.type === "paypal";
};

export const isGoogle = (subscription?: Subscription): boolean => {
  return subscription?.type === "google";
};

export const isApple = (subscription?: Subscription): boolean => {
  return subscription?.type === "apple";
};

export const isAmazon = (subscription?: Subscription): boolean => {
  return subscription?.type === "amazon";
};

type IsFamilyPlan = {
  (type: "product", product?: Product): boolean;
  (type: "subscription", subscription?: Subscription): boolean;
};
export const isFamilyPlan: IsFamilyPlan = (type, obj): boolean => {
  if (type === "product") {
    return obj?.external_id === "yearly_family_subscription";
  }
  return obj?.product === "yearly_family_subscription";
};

export const isFamilyOwner = (subscription?: Subscription): boolean => {
  return subscription?.is_owner === true;
};

export const hasFamilyInvite = () => {
  return !!window.sessionStorage.getItem("inviteCode");
};

export const isModifiable = (subscription?: Subscription): boolean => {
  return !isFamilyPlan("subscription", subscription) && !isFree(subscription);
};

export const isCancellable = (subscription?: Subscription): boolean => {
  return (
    isWebSubscription(subscription) &&
    !isFree(subscription) &&
    isFamilyOwner(subscription) &&
    isRenewing(subscription)
  );
};

export const isRestartable = (subscription?: Subscription): boolean => {
  return (
    isWebSubscription(subscription) &&
    !isFree(subscription) &&
    isFamilyOwner(subscription) &&
    !isRenewing(subscription) &&
    !!subscription.price_id
  );
};

export const isCurrentProduct = (
  subscription: Subscription,
  product: Product,
): boolean => {
  return product.external_id === subscription.product;
};

export const currentProduct = (
  subscription: Subscription,
  products: Product[],
): Product | undefined => {
  return products.find((product) => isCurrentProduct(subscription, product));
};

export const periodToDays = (period: PlanPeriod, date?: Date): number => {
  switch (period.unit) {
    case "day":
      return period.number;
    case "week":
      return period.number * 7;
    case "month":
      return date
        ? differenceInCalendarDays(addMonths(date, period.number), date)
        : period.number * 30;
    case "year":
      return period.number * 365;
    default:
      return 0;
  }
};

export const isLongerPeriod = (
  product1: Product,
  product2: Product,
): boolean => {
  if (!product1 || !product2) return false;
  return (
    periodToDays(product1.billing_period) >
    periodToDays(product2.billing_period)
  );
};

export const hasExpired = (subscription?: Subscription): boolean => {
  if (!subscription) return false;
  return isPast(new Date(subscription?.end_date));
};

export const isInTrial = (subscription?: Subscription): boolean => {
  return (
    subscription?.is_trial &&
    subscription?.trial_ends_at &&
    isFuture(new Date(subscription.trial_ends_at))
  );
};

export const isRenewing = (subscription?: Subscription): boolean => {
  return subscription?.is_renewing;
};

export const hasBillingIssues = (subscription?: Subscription): boolean => {
  return subscription?.billing_issues_at && isRenewing(subscription);
};

export const hasDuplicates = (subscriptions: Subscription[]): boolean => {
  return subscriptions?.length > 1;
};

export const currentStatus = (
  subscription?: Subscription,
): "active" | "trial" | "cancelled" => {
  if (!isRenewing(subscription)) return "cancelled";
  if (isInTrial(subscription)) return "trial";
  return "active";
};

export const nextPaymentDate = (
  subscription?: Subscription,
): Date | undefined => {
  const date = subscription?.end_date;
  if (!date) return;
  return subDays(new Date(date), 1);
};

export const monthlyPriceOfYearly = (price: number): number => {
  return price / 12 - 0.01;
};
