import { z } from "zod";

import { queueItemSchema } from "./queueItemSchema";

export const queueSchema = z.object({
  current_item_index: z.number(),
  items: queueItemSchema.array(),
});

export type Queue = z.infer<typeof queueSchema>;
