import type { CloudfrontCookie } from "../../../lib";
import { URL_BIBLE } from "../../../lib";
import { bibleManifestSchema, signCFUrl } from "../../../lib";
import type { RequestProps } from "../../../request";
import { request } from "../../../request";

export type RequestBibleManifestProps = Partial<RequestProps> & {
  cloudfrontCookies: Array<CloudfrontCookie>;
  translationId: number;
};

export const requestBibleManifest = ({
  cloudfrontCookies,
  translationId,
}: RequestBibleManifestProps) =>
  request({
    next: { tags: ["bible"] },
    requestType: "trusted",
    schema: bibleManifestSchema,
    url: signCFUrl(
      `${URL_BIBLE}/translations/${translationId}/manifest.json`,
      cloudfrontCookies,
    ),
  });
