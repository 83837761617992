import { z } from "zod";

import { sectionSchema } from "./home";

export const pagesSchema = z.object({
  id: z.number(),
  name: z.string(),
  sections: sectionSchema.array(),
});

export type Pages = z.infer<typeof pagesSchema>;
