import { request } from "../../../request";
import { sessionRefreshSchema } from "../../types";

export type RequestSessionRefreshProps = {
  refreshToken?: string;
};

export const requestSessionRefresh = (
  { refreshToken }: RequestSessionRefreshProps = { refreshToken: null },
) =>
  request({
    body: refreshToken ? JSON.stringify({ refresh_token: refreshToken }) : null,
    method: "POST",
    requestType: "default",
    schema: sessionRefreshSchema,
    urlBackendPath: "/oauth/refresh",
  });
