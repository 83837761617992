import type { HallowQueries } from "../types";

export const appendQueries = (queries: HallowQueries) => {
  let q = "";

  for (const [key, value] of Object.entries(queries)) {
    q += `&${key}=${encodeURIComponent(value)}`;
  }

  return q;
};
