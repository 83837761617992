import { sub } from "date-fns";

export type GetDateByRangeProps = {
  range: "monthly" | "quarterly" | "weekly" | "yearly";
};

export const getDateByRange = ({ range }: GetDateByRangeProps) => {
  const date = new Date().setMinutes(0, 0);

  switch (range) {
    case "monthly":
      return sub(date, { days: 30 });
    case "quarterly":
      return sub(date, { days: 90 });
    case "weekly":
      return sub(date, { days: 7 });
    case "yearly":
    default:
      return sub(date, { days: 365 });
  }
};
