"use client";

import type { QueueContentType, QueuePosition } from "../../../../lib";
import type { UseRequestProps } from "../../../../request";
import { useRequest } from "../../../../request";

export type UseRequestQueueItemsProps = UseRequestProps;

export const useRequestQueueItems = ({
  ...props
}: UseRequestQueueItemsProps = {}) => ({
  requestPost: useRequest({
    ...props,
    fnRequestProps: (data: {
      content: {
        content_id: number;
        content_type: QueueContentType;
      };
      queue_position: QueuePosition;
    }) => ({
      body: JSON.stringify(data),
      method: "POST",
      urlBackendPath: "/queue/items",
    }),
  }),
});
